import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Vocal Chords Privacy Policy`}</h2>
    <p>{`We only use the microphone when you are recording with the record button as per the primary functionality of the app.`}</p>
    <p>{`We send no recording data to any servers.`}</p>
    <p>{`The data is under your control unless you choose to share it with other apps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      